<template>
  <div>
    <v-overlay :value="isLoading" class="d-flex align-center justify-center">
      <v-progress-circular indeterminate size="64" color="primary"></v-progress-circular>
    </v-overlay>
    <v-data-table dense disable-pagination hide-default-footer :loading="loadingTable" :headers="headers"
      :items="occupation" class="elevation-1">
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Relatório de Ocupação</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-menu :offset-y="true">
            <template v-slot:activator="{ on: menu, attrs }">
              <v-tooltip top>
                <template v-slot:activator="{ on: tooltip }">
                  <v-btn color="primary" outlined icon large v-bind="attrs" v-on="{ ...tooltip, ...menu }">
                    <v-icon>
                      {{ icons.mdiTools }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>Ferramentas</span>
              </v-tooltip>
            </template>
            <v-list>
              <v-list-item @click="sendExportPdf()">
                <v-list-item-title>
                  <v-icon>{{ icons.mdiFilePdfOutline }}</v-icon> Exportar PDF
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" icon small outlined large class="ml-2" color="primary"
                @click="sendExportPdf()">
                <v-icon>{{ icons.mdiPrinter }}</v-icon>
              </v-btn>
            </template>
            <span>Imprimir</span>
          </v-tooltip>
          <v-badge :content="bagFilter" :value="bagFilter" bordered color="error" overlap>
            <v-btn @click="dialogFilter = true" class="ml-2" color="primary" outlined rounded>
              <v-icon>{{ icons.mdiFilter }}</v-icon>Filtros
            </v-btn>
          </v-badge>
          <v-spacer></v-spacer>
          <span>Periodo: de &nbsp <b style="color:black">{{ formatDate(filter.date_start) }}</b>&nbsp até &nbsp <b
              style="color:black">{{ formatDate(filter.date_end) }}</b></span>
        </v-toolbar>
      </template>
      <template v-slot:item.percentuals="{ item }">
        <span>{{ formatMoney(item.percentuals) }}%</span>
      </template>

      <template v-slot:foot>
        <tr style="height:44px; background:#fafafa">
          <td :colspan="numbercol" class="text-right"
            style="border-top:solid 1px #e4e3e6;padding-left:16px;padding-right:16px">
            OCUPAÇÃO DO PERIODO:</td>
          <td class="text-right" style="border-top:solid 1px #e4e3e6 ;padding-left:16px;padding-right:16px">
            {{ formatMoney(total) }}%
          </td>
        </tr>
      </template>

      <template v-slot:no-data>
        <NoDataTable></NoDataTable>
      </template>
      <template v-slot:loading>
        Carregando...
      </template>

    </v-data-table>

    <!-- DIALOG FILTER -->
    <v-dialog v-model="dialogFilter" width="700px">
      <v-card>
        <BarTitleDialog title="Filtros" @close="dialogFilter = false" />
        <v-card-text>
          <v-row>
            <v-col>
              <v-card outlined>
                <v-card-text>
                  <v-row no-gutters>
                    <v-col>
                      <v-text-field v-model="filter.date_start" :error-messages="error.date_start"
                        @change="error.date_start = null" outlined label="Data inicial" :max="filter.date_end"
                        type="date"></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field class="ml-2" v-model="filter.date_end" :error-messages="error.date_end"
                        @change="error.date_end = null" outlined label="Data final" :max="date_max"
                        type="date"></v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-row>
            <v-col class="text-left">
              <v-btn text color="error" x-large @click="clearFilter()">limpar filtros</v-btn>
            </v-col>

            <v-col class="text-right">
              <v-btn text color="secondary" x-large @click="dialogFilter = false">FECHAR</v-btn>
              <v-btn :loading="loadingFilter" :disabled="loadingFilter" @click="confirmFilter()" color="primary"
                class="ml-2" x-large>aplicar</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <MsgDelete v-model="dialogDelete" @delete-yes="deleteItemConfirm()" />
  </div>
</template>

<script>
import MsgDelete from '@/components/MsgDelete.vue'
import NoDataTable from '@/components/NoDataTable.vue'
import { mdiClose, mdiTools, mdiFileExcelOutline, mdiFilter, mdiPrinter, mdiFilePdf, mdiFilePdfOutline } from '@mdi/js'
import { mapActions, mapGetters } from 'vuex'
import BarTitleDialog from '@/components/BarTitleDialog.vue'
import SelectPdv from '@/components/SelectPdv.vue'

export default {
  components: {
    MsgDelete,
    NoDataTable,
    BarTitleDialog,
    SelectPdv,
  },
  data: () => ({
    // DIALOG FILTER
    dialogFilter: false,
    date_max: new Date().toISOString().slice(0, 10),
    filter: {},
    itemsGroupProduct: [],
    loadingFilter: false,
    categories: [],
    numbercol: 6,
    categoryItems: [],
    loadingTable: false,
    totalStagesPaginate: 0,
    numberOfPagesPaginate: 0,
    currentPagePaginate: 0,
    dialogDelete: false,
    dialog: false,
    loading: false,
    isLoading: false,
    total: 0,
    headers: [

    ],
    occupation: [],
    editedIndex: -1,
    editedItem: {
      description: '',
    },
    defaultItem: {
      description: '',
    },
    options: {},
    icons: {
      mdiTools,
      mdiClose,
      mdiFileExcelOutline,
      mdiFilter,
      mdiPrinter,
      mdiFilePdf,
      mdiFilePdfOutline,
    },
    error: {
      date_start: null,
      date_end: null,
    },
  }),

  computed: {
    bagFilter() {
      return Object.keys(this.filter).length
    },
  },

  watch: {
    options: {
      handler() {
        this.initialize()
      },
    },
  },

  created() {
    this.periodInitial()
    this.initialize()
  },

  methods: {
    ...mapActions('occupation_report', ['getAll', 'exportPdf']),


    initialize() {
      this.loadingTable = true

      //   const { page, itemsPerPage } = this.options

      //   const payload = {
      //     itemsPerPage: itemsPerPage || 10,
      //     page,
      //     search: this.search,
      //   }
      this.getAll(this.filter)
        .then(response => {
          this.categories = response.data.categories
          console.log(this.categories)
          this.getHeader()
          this.occupation = response.data.data
          this.total = response.data.percentuals_avg
          this.dialogFilter = false
        })
        .finally(() => {
          this.loadingTable = false
          this.loadingFilter = false
          this.dialogFilter = false
        })
    },

    periodInitial() {
      let dateToday = new Date();
      dateToday.setHours(dateToday.getHours() - 3);

      this.filter.date_end = dateToday.toISOString().slice(0, 10)

      dateToday.setDate(dateToday.getDate() - 15);

      this.filter.date_start = dateToday.toISOString().slice(0, 10)
    },
    confirmFilter() {
      //validar filtro periodo

      if (!this.filter.date_start) {
        this.error.date_start = 'Campo obrigatório'
        return false
      }
      if (!this.filter.date_end) {
        this.error.date_end = 'Campo obrigatório'
        return false
      }
      this.loadingFilter = true
      this.initialize()
    },
    sendExportPdf() {
      this.isLoading = true
      this.exportPdf(this.filter)
        .then(response => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]))
          var fileLink = document.createElement('a')
          fileLink.href = fileURL
          fileLink.setAttribute('download', 'export.pdf')
          document.body.appendChild(fileLink)
          fileLink.click()
          this.isLoading = false
        })
        .finally()
    },
    formatMoney(value) {
      if (value != null) {
        return value.toLocaleString('pt-br', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      }
    },
    formatDate(dateTime) {
      if (!dateTime) return null

      const [date, time] = dateTime.split('T')
      const [year, month, day] = date.split('-')

      return `${day}/${month}/${year} `
    },

    getHeader() {
      this.headers = [
        {
          text: 'DATA/CATEGORIA',
          align: 'start',
          sortable: false,
          value: 'date',
        },
      ]
      this.categories.forEach(category => {
        this.headers.push({
          text: category.toUpperCase(),
          align: 'center',
          sortable: false,
          value: category,
        })
      })
      this.headers.push({
        text: 'TOTAL',
        align: 'end',
        sortable: false,
        value: 'total',
      })
      this.headers.push({
        text: '%OCUPAÇÂO',
        align: 'end',
        sortable: false,
        value: 'percentuals',
      })

      this.numbercol = this.headers.length - 1
    },

  },

}
</script>
